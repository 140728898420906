@import "../../App.scss";

$mediaMobile: 800px;
$mediaMedium: 1200px;

.about-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* border: 2px solid white; */

  .about-title {
    font-size: calc(24px + (28 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: white;
    filter: drop-shadow(2px 2px #000);
    font-weight: 900;
    margin: 0 18vh 1.5vh;
  }

  .about-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media screen and (max-width: $mediaMobile) {
      flex-direction: column;
      height: 110vh;
    }

    .about-info-container {
      width: 35vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 3vw;
      @media screen and (max-width: $mediaMobile) {
        width: 70vw;
      }

      .about-paragraph {
        font-size: calc(12px + (22 - 14) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 600;
        margin: 0 0 4vh;
        text-align: center;
        color: white;
      }

      .subheading {
        font-size: calc(18px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
        background-image: $grade-color;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
        filter: drop-shadow(2px 2px #000);
        font-weight: 700;
        margin: 0.5rem 0;
      }
    }

    .about-image-container {
      position: relative;
      display: inline-block; //border hugs included elements

      .about-image {
        width: 35vw;
        @media screen and (max-width: $mediaMobile) {
          width: 70vw;
        }
      }

      .pup-title {
        background-image: $grade-color;
        color: white;
        text-shadow: 2px 2px #000;
        filter: drop-shadow(2px 2px #000);
        /* -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; */
        padding: 0 6%;
        position: absolute;
        font-weight: bolder;
        font-size: calc(16px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
      }

      .banzai-title {
        text-align: right;
        left: -2%;
        top: 13%;
      }

      .penny-title {
        right: -2%;
        bottom: 25%;
        & > p {
          text-align: left;
          font-size: calc(10px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
        }
      }
    }
  }
}
