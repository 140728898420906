@import "../../App.scss";

@mixin animate($duration) {
  animation: $duration;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

$mediaMobile: 800px;
$mediaMedium: 1200px;

.navbar-container {
  position: sticky;
  top: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 2.5rem 2rem;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.navbar-menu {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  border-radius: 50%;
  margin: 1rem;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: all 0.3 ease-in-out;
  @keyframes menuLoad {
    0% {
      left: -5vw;
    }
  }
  animation: menuLoad 0.5s;
}

.burger {
  background: white;
  height: clamp(0.25rem, 0.3rem, 2rem);
  width: min(1.9rem, 6vw);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: white;
    width: clamp(0.25rem, 2.5rem, 3.5rem);
    height: clamp(0.25rem, 0.3rem, 2rem);
    width: min(2.5rem, 8vw);

    border-radius: 8px;
    transition: transform 0.5s ease-in-out;
  }
  &::before {
    transform: rotate(0deg) translateY(-0.8rem);
  }
  &::after {
    transform: rotate(0deg) translateY(0.8rem);
  }
}

.burger-clicked {
  background: transparent;
  &::before {
    transform: rotate(45deg) translateY(13px) translateY(-0.8rem);
  }
  &::after {
    transform: rotate(-45deg) translateY(-13px) translateY(0.8rem);
  }
}

.burger-showNav {
  visibility: visible;
}

.navbar {
  width: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.navbar-tags {
  color: white;
  font-size: clamp(1.5rem, 3.5vw, 2rem);
  font-weight: bold;
  transition: 0.3s ease-in-out all;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  & > span {
    color: #0473fc;
    font-size: clamp(1rem, 2vw, 2rem);
    vertical-align: top;
  }
  &:hover {
    background-image: $grade-color;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and (max-width: $mediaMobile) {
  .navbar-menu {
    margin: 0.5rem;
  }

  // .burger {
  //   height: clamp(0.1rem, 0.2rem, 1rem);
  //   width: min(1rem, 6vw);
  //   border-radius: 5px;
  //   margin: 0.3rem 0.5rem 0 0;
  //   &::before,
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     background: white;
  //     width: clamp(0.2rem, 2rem, 3rem);
  //     height: clamp(0.2rem, 0.3rem, 2rem);
  //     width: min(1.5rem, 8vw);
  //   }
  // }

  .navbar-container {
    height: 7rem;
  }

  .navbar {
    flex-direction: column;
  }

  // .navbar {
  //   flex-direction: ${(props) => (props.clicked && props.showNav ? 'none' : 'column')};
  //   opacity: ${(props) => (props.clicked && props.showNav ? '0' : '1')};
  // }

  .navbar-tags {
    font-size: clamp(1.1rem, 3vw, 2rem);
    & > span {
      font-size: clamp(0.5rem, 1vw, 2rem);
    }
  }
}
