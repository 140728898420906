@import "../../App.scss";

$mediaMobile: 800px;
$mediaMedium: 1200px;

.hero-container {
  height: 110vh;
  text-align: start;
  color: white;
  text-shadow: 2px 2px #000;
  font-size: calc(16px + (30 - 14) * ((100vw - 300px) / (1600 - 300)));
  overflow: hidden;
  position: relative;
  top: -9rem;
  background: transparent;

  .intro-container {
    position: relative;
    top: 22rem;
    left: 7rem;
    // transform: rotate(-25deg);

    @media screen and (max-width: $mediaMobile) {
      top: 25vh;
      left: 5vw;
    }

    font-weight: 900;
    @keyframes heroLoad {
      0% {
        left: -8vw;
      }
    }
    animation: heroLoad 0.7s;

    & > * {
      margin: 7px 0;
    }
    & > h1 {
      display: inline-block;
      font-size: 5rem;
      // background-image: $grade-color;
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // text-shadow: none;
      // filter: drop-shadow(2px 2px #000);
    }

    .hero-descriptions {
      margin: 0 10vw 0 0;
      & > .typed {
        font-weight: bolder;
        background-image: $grade-color;
      }
      @media screen and (max-width: $mediaMobile) {
        width: 90vw;
        min-width: 14rem;
      }
    }

    .hero-links {
      & > span {
        margin: 0px 6px;
        background-image: $grade-color;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
        filter: drop-shadow(2px 2px #000);
      }

      .hero-link {
        .hero-link-atag {
          text-decoration: none;
          color: #0473fc;
          &:hover {
            background-image: $grade-color;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: none;
            filter: drop-shadow(2px 2px #000);
          }
        }
      }
    }
  }

  .hero-background-img {
    position: absolute;
    background-image: url("../../photos/coffee1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    filter: grayscale(40%) brightness(45%);
    width: 100%;
    height: 100%;

    @keyframes menuLoad {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: imageLoad ease 2s;
  }

  .hero-icons-container {
    /* display: flex; */
    align-items: right;
    position: fixed;
    right: 5px;
    bottom: 10px;
    list-style-type: none;
    z-index: 100;

    .hero-icons {
      margin: 5px 10px;
      & > a {
        color: white;
      }
      & > #git:hover {
        color: #0473fc;
      }
      & > #mail:hover {
        color: #7b00ac;
      }
      & > #linked:hover {
        color: #bc0098;
      }
      & > #resume:hover {
        color: #ff0099;
      }
    }
  }
}
