@import "../../App.scss";

@mixin form-styles {
  width: 100%;
  text-align: left;
  margin: 0 0 1vh;
  padding: 2px;
  border-radius: 5px;
  &:focus {
    background-color: hsl(210, 31%, 80%);
  }
}

$mediaMobile: 800px;
$mediaMedium: 1200px;

.connect-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .connect-title {
    font-size: calc(24px + (28 - 14) * ((100vw - 300px) / (1600 - 300)));
    margin: 3vh;
    color: white;
    filter: drop-shadow(2px 2px #000);
    font-weight: 900;
  }

  .connect-form-container {
    /* border: 2px solid white; */
    position: relative;
    display: inline-block;
    border-radius: 30px;
    background-image: $grade-color;
    @media screen and (max-width: $mediaMobile) {
      width: 90vw;
      min-width: 15rem;
    }
    @media screen and (max-width: $mediaMedium) {
      width: 50vw;
    }

    .connect-form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: calc(12px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 800;
      color: white;
      text-shadow: 2px 2px #000;
      margin: 3vh 3vw 1vh;

      .connect-form-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .connect-form-label {
          @include form-styles;
        }

        .connect-form-input {
          @include form-styles;
          height: 4vh;
        }

        .connect-comments {
          @include form-styles;
          height: 8vh;
        }
      }

      .connect-submit-button {
        padding: 4px;
        font-weight: 900;
        font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
        border-radius: 8px;
        cursor: pointer;
        margin: 4px;
        background: transparent;
        color: white;
        border-color: white;
        &:hover {
          background-color: hsl(210, 31%, 80%);
        }
      }

      .submit-response {
        // opacity: ${props => props.opacity ? '1' : '0'};
        // transform: ${props => props.transform ? 'none' : 'translateY(20%)'};
        transition: all 0.5s ease-in-out;
      }
    }
  }
}

// const UploadFormInput = styled.input`
//     height: 0;
//     width: 0;
//     opacity: 0;
//     &::file-selector-button {
//         padding: 4px;
//         font-weight: 700;
//         border-radius: 20px;
//         cursor: pointer;
//         font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
//         font-family: 'Raleway';
//         src: url(${Raleway});
//     }
// `

// const UploadFormButton = styled(ConnectSubmitButton)`
//     &::file-selector-button {

//     }
// `
