@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-VariableFont_wght.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

body {
  position: relative;
}

body::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // opacity: 0.9;
  background-image: url("./photos/circuit-board.svg");
  background-color: #1f1f1f;
  color: hsl(0, 0%, 100%);
}
